import classes from "./Decor.module.css";
import decor1 from '../../img/decor/decor-1.png'
import decor2 from '../../img/decor/decor-2.png'
import decor3 from '../../img/decor/decor-3.png'
import decor5 from '../../img/decor/decor-5.png'
import decor6 from '../../img/decor/decor-6.png'
import decor7 from '../../img/decor/decor-7.png'
import decor8 from '../../img/decor/decor-8.png'
import decor9 from '../../img/decor/decor-9.png'
import decor10 from '../../img/decor/decor-10.png'

export default function Decor() {
  return <div className={classes.container}>
    <img src={decor1} className={classes.decor} style={{top: '3.5rem', left: '2rem', '--dl': '0'}} />
    <img src={decor2} className={classes.decor} style={{top: '5rem', right: '2rem', '--dl': '100ms', width: '3rem'}} />
    <img src={decor3} className={classes.decor} style={{top: '24rem', left: '2rem', '--dl': '200ms', width: '3rem'}} />
    <img src={decor5} className={classes.decor} style={{top: '23rem', right: '2rem', '--dl': '300ms', width: '2.5rem'}} />
    <img src={decor9} className={classes.decor} style={{top: '10rem', left: '1rem', '--dl': '400ms', width: '2rem'}} />
    {/* <img src={decor7} className={classes.decor} style={{bottom: '8.5rem', left: '52%', '--dl': '500ms'}} /> */}
    <img src={decor6} className={classes.decor} style={{top: '3rem', left: '51%', '--dl': '600ms'}} />
    <img src={decor8} className={classes.decor} style={{top: '40%', left: '2rem', '--dl': '700ms', width: '3rem'}} />
    <img src={decor10} className={classes.decor} style={{top: '39%', left: '42%', '--dl': '800ms', width: '3rem'}} />

  </div>;
}
