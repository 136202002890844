import classes from "./Emblem.module.css";

export default function Emblem({ style }) {
  return (
    <div className={classes.emblemContainer} style={style}>
      <h2 className={classes.h2}>Smart-Lotto</h2>
      <h1 className={classes.h1}>
        <span>O</span>
        <span>G</span>
        <span>G</span>
        <span>O</span>
        <span>!</span>
      </h1>
    </div>
  );
}
