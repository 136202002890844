import classes from "./MiddlePart.module.css";
import trophy from "./../../img/trophy.svg";
import superBtnCircle1 from "./../../img/decor/superBtnCircle1.png";
import superBtnCircle2 from "./../../img/decor/superBtnCircle2.png";
import superBtnCircle3 from "./../../img/decor/superBtnCircle3.png";
import {LOTTERY_NUMBER_OFFSET} from "../../config";

export default function MiddlePart({
  openChooseTourModal,
  drawNumber,
  ticketsPurchased,
  shortSuperPrize,
}) {
  return (
    <div className={classes.middlePart}>
      <div className={classes.superPrize}>
        <span className={classes.superPrizeEl}>
          <span>Super</span> <span>Prize</span>
        </span>
        <span className={classes.superPrizeEl} id="superPrize">
          {shortSuperPrize}
        </span>
        <span className={classes.superPrizeEl}>USDZ</span>
      </div>

      <button className={classes.superButton} onClick={openChooseTourModal}>
        Play
      </button>
      <img className={classes.superButtonCircles1} src={superBtnCircle1}></img>
      <img className={classes.superButtonCircles2} src={superBtnCircle2}></img>
      <img className={classes.superButtonCircles3} src={superBtnCircle3}></img>

      <div className={classes.currentDraw}>
        Draw <span id="drawNumber">№{drawNumber+1-LOTTERY_NUMBER_OFFSET}</span>
        <br />
        Tickets purchased: <span id="drawNumber">{ticketsPurchased}</span>
      </div>

      <div className={classes.rating}>
        <span id="rating">Tourney</span>
        <img src={trophy} alt="trophy" />
      </div>
    </div>
  );
}
