import classes from "./ButtonPanel.module.css";

export default function ButtonPanel({
  buyTicketFunction,
  myTicketsFunction,
  profileFunction,
}) {
  return (
    <div className={classes.buttonPanel}>
      <button className={classes.button} onClick={buyTicketFunction}>
        Buy ticket
      </button>
      <button className={classes.button} onClick={myTicketsFunction}>
        My tickets
      </button>
      <button className={classes.button} onClick={profileFunction}>
        Profile
      </button>
    </div>
  );
}
