import classes from './Window.module.css'

export default function Window(props) {
  if (props.show) {
    window.Telegram.WebApp.BackButton.show()
    window.Telegram.WebApp.BackButton.onClick(()=>{window.Telegram.WebApp.BackButton.hide();props.handleClose()})
  }

  return (
  <div className={classes.window} style={{ display: props.show ? "" : "none" }}>
    {props.children}
  </div>
  )
}