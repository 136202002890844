import logo from "./logo.svg";
import "./App.css";
import Emblem from "./components/Emblem/Emblem";
import MiddlePart from "./components/MiddlePart/MiddlePart";
import ButtonPanel from "./components/ButtonPanel/ButtonPanel";
import Firework from "./components/Firework/Firework";
import Header from "./components/Header/Header";
import ConnectWalletModal from "./components/ConnectWalletModal/ConnectWalletModal";
import { useState, useEffect } from "react";
import { TonConnectUIProvider, THEME } from "@tonconnect/ui-react";
import { HOST, SERVER, WEBAPP_TG_URL } from "./config";
import ChooseTourModal from "./components/ChooseTourModal/ChooseTourModal";
import ShowTourWindow from "./components/ShowTourWindow/ShowTourWindow";
import ProfileWindow from "./components/ProfileWindow/ProfileWindow";
import BuyTicketModal from "./components/BuyTicketModal/BuyTicketModal";
import MyTicketsModal from "./components/MyTicketsModal/MyTicketsModal";
import WithdrawModal from "./components/WithdrawModal/WithdrawModal";
import Decor from "./components/Decor/Decor";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(()=>{
      setIsLoading(false);
    }, 1000)
  });

  const [isConnectWalletModal, setConnectWalletModal] = useState(false);
  const [isChooseTourModal, setChooseTourModal] = useState(false);
  const [isBuyTicketModal, setBuyTicketModal] = useState(false);
  const [isMyTicketsModal, setMyTicketsModal] = useState(false);
  const [isWithdrawTicketModal, setWithdrawTicketModal] = useState(false);

  const [showingTour, setShowingTour] = useState(1);

  const [isShowTourWindow, setIsShowTourWindow] = useState(false);
  const [isProfileWindow, setIsProfileWindow] = useState(false);

  const initData = window.Telegram.WebApp.initData

  const [ZHCashAddress, setZHCashAddress] = useState("");
  const [balanceUSDZ, setbalanceUSDZ] = useState(0);
  const [balanceLOT, setbalanceLOT] = useState(0);

  function loadZHCashWalletData() {
    fetch(`${SERVER}/load-zhcash-wallet-data`, {
      headers: {
        Authorization: `tma ${initData}`,
      }})
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "error" && data.message === "User not found") {alert("User not found. Please send /start to bot.");window.Telegram.WebApp.close()}
        setZHCashAddress(data.address);
        setbalanceUSDZ(data.balanceUSDZ);
        setbalanceLOT(data.balanceLOT);
      });
  }

  const [drawNumber, setDrawNumber] = useState(0);
  const [ticketsPurchased, setTicketsPurchased] = useState(0);
  // const [superPrize, setSuperPrize] = useState(0);
  const [shortSuperPrize, setShortSuperPrize] = useState("0");
  function loadDrawInfo() {
    fetch(`${SERVER}/get-draw-info`, {
      headers: {
        Authorization: `tma ${initData}`,
      }})
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "error" && data.message === "User not found") {alert("User not found. Please send /start to bot.");window.Telegram.WebApp.close()}
        setDrawNumber(data.drawNumber);
        setTicketsPurchased(data.ticketsPurchased);
        setShortSuperPrize(data.shortSuperPrize);
      });
  }

  const [myTickets, setMyTickets] = useState([]);
  function loadMyTickets() {
    fetch(`${SERVER}/get-user-tickets`, {
      headers: {
        Authorization: `tma ${initData}`,
      }})
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "error" && data.message === "User not found") {alert("User not found. Please send /start to bot.");window.Telegram.WebApp.close()}
        setMyTickets(data.tickets)
      });
  }


  useEffect(() => {
    loadZHCashWalletData();
    loadDrawInfo();
    loadMyTickets();
  }, []);


  return (
    <>
      {isLoading ? (
        <div className="appLoading">
          <span className="loader"></span>
        </div>
      ) : null}
      <TonConnectUIProvider
        manifestUrl={`${HOST}/tonconnect-manifest.json`}
        // language={window.Telegram.WebApp.initDataUnsafe.user.language_code}
        uiPreferences={{ borderRadius: "s", theme: THEME.DARK }}
        twaReturnUrl={WEBAPP_TG_URL}
        // actionsConfiguration={
        //   window.Telegram.WebApp.platform === "tdesktop"
        //     ? { twaReturnUrl: WEBAPP_TG_URL }
        //     : null
        // }
      >
        <div className="App">
          <Decor />
          <Firework />

          <Header openConnectWalletModal={() => setConnectWalletModal(true)} 
              balanceUSDZ={balanceUSDZ} 
              openWithdrawModal={() => {setWithdrawTicketModal(true)}} />
          <Emblem />

          <MiddlePart openChooseTourModal={() => setChooseTourModal(true)} drawNumber={drawNumber} ticketsPurchased={ticketsPurchased} shortSuperPrize={shortSuperPrize} />

          <ButtonPanel
            profileFunction={() => setIsProfileWindow(true)}
            buyTicketFunction={() => setBuyTicketModal(true)}
            myTicketsFunction={() => setMyTicketsModal(true)}
          />

          {/* Windows */}
          <ShowTourWindow
            show={isShowTourWindow}
            showingTour={showingTour}
            handleClose={() => setIsShowTourWindow(false)}
          />
          <ProfileWindow
            show={isProfileWindow}
            handleClose={() => setIsProfileWindow(false)}
            balanceLOT={balanceLOT}
          />

          {/* Modals */}
          <ConnectWalletModal
            show={isConnectWalletModal}
            handleClose={() => setConnectWalletModal(false)}
            ZHCashAddress={ZHCashAddress}
          />
          <ChooseTourModal
            show={isChooseTourModal}
            handleClose={() => setChooseTourModal(false)}
            openShowTourWindow={() => {setChooseTourModal(false);setIsShowTourWindow(true)}}
            showingTour={showingTour}
            setShowingTour={setShowingTour}
            drawNumber={drawNumber}
          />
          <BuyTicketModal
            show={isBuyTicketModal}
            handleClose={() => setBuyTicketModal(false)}
            balanceUSDZ={balanceUSDZ}
          />
          <MyTicketsModal
            show={isMyTicketsModal}
            handleClose={() => setMyTicketsModal(false)}
            tickets={myTickets}
          />
          <WithdrawModal
            show={isWithdrawTicketModal}
            handleClose={() => setWithdrawTicketModal(false)}
            balanceUSDZ={balanceUSDZ}
          />
        </div>
      </TonConnectUIProvider>
    </>
  );
}

export default App;
