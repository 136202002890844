import classes from "./ChooseTourModal.module.css";
import Modal from "../Modal/Modal";
import { useState } from "react";

export default function ChooseTourModal(props) {
  const [nextDrawIn, setNextDrawIn] = useState("0d 0h 0m 0s");

  const getDateOfNearestDay = (day, hourThreshold = 18, date = new Date()) => {
    if (date.getHours() >= hourThreshold) {
      if (date.getDay() === day) return new Date(date.getFullYear(), date.getMonth(), date.getDate()+7);
    }
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + ((7 + day - date.getDay()) % 7)
    )
  };

  function updateNextDrawIn() {
    let nextDraw = getDateOfNearestDay(5);
    nextDraw.setHours(18);

    let timeLeft = nextDraw - Date.now();
    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
    setNextDrawIn(`${days}d ${hours}h ${minutes}m ${seconds}s`);
  }

  setInterval(updateNextDrawIn, 1000);

  return (
    <Modal show={props.show} handleClose={props.handleClose}>
      <div className={classes.container}>
        <div className={classes.onCounter}>Next draw in:</div>
        <div className={classes.counter}>{nextDrawIn}</div>

        { props.drawNumber !== 0 && (
          <>
          <button
            className={classes.tourBtn + " " + classes.tour1}
            onClick={()=>{props.setShowingTour(1);props.openShowTourWindow()}}
          >
            Tour 1
          </button>

          <button
            className={classes.tourBtn + " " + classes.tour2}
            onClick={()=>{props.setShowingTour(2);props.openShowTourWindow()}}
          >
            Tour 2
          </button>

          <button
            className={classes.tourBtn + " " + classes.tour3}
            onClick={()=>{props.setShowingTour(3);props.openShowTourWindow()}}
          >
            Tour 3
          </button>

          <button
            className={classes.tourBtn + " " + classes.tour4}
            onClick={()=>{props.setShowingTour(4);props.openShowTourWindow()}}
          >
            Tour 4
          </button>
          </>
        )}
      </div>
    </Modal>
  );
}
