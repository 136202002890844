import classes from "./BuyTicketModal.module.css";
import Modal from "../Modal/Modal";
import { useEffect, useState } from "react";
import USDZ_LOGO from "./../../img/usdz-logo.png";
import Ton from "./../../img/ton.png";
import { useTonConnectUI, useTonWallet } from "@tonconnect/ui-react";
import { SERVER } from "../../config";

export default function BuyTicketModal(props) {
  const [paymentMethod, setPaymentMethod] = useState("zhcash");
  const [numberTickets, setNumberTickets] = useState("1");

  const [tonConnectUI] = useTonConnectUI();

  const tonWallet = useTonWallet();

  const [tonExchangeRate, setTonExchangeRate] = useState(8);
  function loadTonPaymentMethodInfo() {
    fetch(`${SERVER}/get-ton-payment-method-info`)
      .then((res) => res.json())
      .then((data) => {
        setTonExchangeRate(data.exchange_rate);
      });
  }

  useEffect(() => {
      loadTonPaymentMethodInfo();
  }, []);

  function updatenumberTicketsInput(event) {
    // if (isNaN(parseInt(event.target.value, 10))) {
    //   return setNumberTickets(1);
    // }
    // setNumberTickets(parseInt(event.target.value, 10));
    // setNumberTickets(Number(event.target.value));
    setNumberTickets(event.target.value);
  }

  function showInfoMessage(title, message) {
    window.Telegram.WebApp.showPopup({title: title, message: message, buttons:[{id: "ok", type:"ok"}]})
  }

  async function buyTicket() {
    const myNumberTickets = Number(numberTickets);
    if (myNumberTickets < 1) {
      return showInfoMessage("Error", "Number of tickets must be greater than 0")
    }
    // if (myNumberTickets > 30) {
    //   return showInfoMessage("Error", "Number of tickets must be less or equal to 30")
    // }
    if (!Number.isInteger(myNumberTickets)) {
      return showInfoMessage("Error", "Number of tickets must be an integer")
    }

    const currentDate = new Date();
    if (currentDate.getUTCDay() === 5 && currentDate.getUTCHours()+3 >= 18-1 && currentDate.getUTCHours()+3 < 18+1) {
      return showInfoMessage("Error", "It is not possible to buy tickets an hour before and after the draw")
    }

    // return showInfoMessage("Error", "Ticket purchase is temporarily unavailable")

    switch (paymentMethod) {
      case "ton":
        await buyTicketWithTon();
        break;
      case "zhcash":
        if (myNumberTickets > props.balanceUSDZ) {
          return showInfoMessage("Error", "Not enough USDZ balance")
        }
        await buyTicketWithZHCash(); 
        break;
      default:
        showInfoMessage("Error", "Please select a payment method")
    }
  }

  async function buyTicketWithTon() {
    await fetch(`${SERVER}/get-ton-payment-method-info`)
      .then((res) => res.json())
      .then(async (data) => {
        setTonExchangeRate(data.exchange_rate)
        // console.log(numberTickets / tonExchangeRate);
        const myTransaction = {
          validUntil: Math.floor(Date.now() / 1000) + 60*2,
          messages: [
            {
              address: data.ton_destination_address,
              amount: Math.floor(Number(numberTickets) / tonExchangeRate * 10**9),
            },
          ],
        };
        
        fetch(`${SERVER}/buy-tickets-ton`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            Authorization: `tma ${window.Telegram.WebApp.initData}`,
          },
          body: JSON.stringify({
            ton_exchange_rate: tonExchangeRate
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.status === "success") {
              // showInfoMessage("Success", "Tickets will appear on your account in a few ZHCash blockchain blocks.")
            } else {
              showInfoMessage("Error", data.message)
            }
          });
        
        try {
          await tonConnectUI.sendTransaction(myTransaction);
          showInfoMessage("Success", "Tickets will appear on your account in a few ZHCash blockchain blocks.")
          props.handleClose();
        } catch (e) {
          console.log(e);
        }
      });
  }

  async function buyTicketWithZHCash() {
    await fetch(`${SERVER}/buy-tickets-usdz`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `tma ${window.Telegram.WebApp.initData}`,
      },
      body: JSON.stringify({
        amount: Number(numberTickets)
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          showInfoMessage("Success", "Tickets will appear on your account in a few ZHCash blockchain blocks.")
        } else {
          showInfoMessage("Error", data.message)
        }
      });
  }


  return (
    <Modal show={props.show} handleClose={props.handleClose}>
      <h2 className={classes.h2 + ' ' + classes.h2First}>Choose a payment method</h2>
      <div className={classes.choosePaymentMethodContainer}>
        <button
          className={
            classes.paymentMethodZHCash +
            " " +
            (paymentMethod === "zhcash" ? classes.active : "")
          }
          onClick={() => {
            setPaymentMethod("zhcash");
          }}
          disabled={false}
        >
          <img src={USDZ_LOGO} />
          USDZ
        </button>

        <button
          className={
            classes.paymentMethodTON +
            " " +
            (paymentMethod === "ton" ? classes.active : "")
          }
          onClick={() => {
            setPaymentMethod("ton");
          }}
          disabled={tonWallet === null}
        >
          <img src={Ton} />
          TON
        </button>
      </div>

      <h2 className={classes.h2}>Number of tickets</h2>

      <input
        className={classes.numberTickets}
        onChange={updatenumberTicketsInput}
        value={numberTickets}
        type="number"
        min="1"
      />

      {paymentMethod === "zhcash" ? (
        <p className={classes.additInfo}>
          1 ticket = 1 USDZ
        </p>
      ) : (
        <p className={classes.additInfo}>
          1 ticket = {Math.ceil(1/tonExchangeRate*1000)/1000} TON
        </p>
      )}

      <button className={classes.buyButton} onClick={buyTicket}>
        Buy
      </button>
    </Modal>
  );
}
