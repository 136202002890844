import classes from "./Header.module.css";

export default function Header({ openConnectWalletModal, balanceUSDZ, openWithdrawModal }) {
  return (
    <header className={classes.header}>
      <span id="balance" onClick={openWithdrawModal}>{Math.floor(balanceUSDZ * 1000) / 1000} USDZ</span>
      <button
        className={classes.connectWalletBtn}
        onClick={openConnectWalletModal}
      >
        Wallets
      </button>
    </header>
  );
}
