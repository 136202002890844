import classes from "./Modal.module.css";
import cross from "./../../img/cross-white.svg";

export default function Modal({ children, show, handleClose, contentClassName }) {
  return (
    <div className={classes.backdrop} style={{ display: show ? "" : "none" }}>
      <div className={classes.modal}>
        <img src={cross} className={classes.closeBtn} onClick={handleClose} />
        <div className={classes.content + ' ' + contentClassName}>
          {children}
        </div>
      </div>
    </div>
  );
}
