import classes from "./ConnectWalletModal.module.css";
import Modal from "../Modal/Modal";
import ZHCash from "./../../img/zhcash_black.png";
import Ton from "./../../img/ton.png";
import TonBG from "./../../img/ton_bg.png";
import Copy from "./../../img/copy.svg";
import Check from "./../../img/check.svg";
import Logout from "./../../img/logout.svg";
import {
  useTonConnectModal,
  useTonWallet,
  useTonAddress,
  useTonConnectUI,
} from "@tonconnect/ui-react";
import { useEffect, useState } from "react";
import { SERVER } from "../../config"

export default function ConnectWalletModal(props) {
  const { state, open, close } = useTonConnectModal();
  const tonWallet = useTonWallet();
  const tonAddress = useTonAddress();
  const [tonConnectUI] = useTonConnectUI();

  const [CopyImg, setCopyImg] = useState(Copy);
  const [CopyImgTON, setCopyImgTON] = useState(Copy);

  const initData = window.Telegram.WebApp.initData

  // const [ZHCashAddress, setZHCashAddress] = useState("");

  // const initData = window.Telegram.WebApp.initData

  // function loadZHCashWalletData() {
  //   fetch(`${SERVER}/load-zhcash-wallet-data`, {
  //     headers: {
  //       Authorization: `tma ${initData}`,
  //     }})
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setZHCashAddress(data.address);
  //     });
  // }

  function updateAddressOnServer() {
    // alert(tonConnectUI.account.address)
    fetch(`${SERVER}/update-ton-address`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `tma ${initData}`,
      },
      body: JSON.stringify({
        address: tonConnectUI.account.address
      })
    });
  }

  function loadUserTonInfo() {
    fetch(`${SERVER}/load-user-ton-info`, {
      headers: {
        Authorization: `tma ${initData}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (tonConnectUI && tonConnectUI.account && data.address !== tonConnectUI.account.address) {
          updateAddressOnServer()
        }
      });
  }

  useEffect(() => {
    // setTimeout(loadUserTonInfo, 2000)
    loadUserTonInfo()
  }, []);
  

  return (
    <Modal show={props.show} handleClose={props.handleClose}>
      <div className={classes.modal}>
        {/* <button className={classes.connectZHCashBtn}>
          <img src={ZHCash} className={classes.connectZHCashBtnImg} />
          Connect ZHCash Wallet
        </button> */}
        <div className={classes.connectedContainer}>
          <div className={classes.addressContainer}>
            <img src={ZHCash} />
            <span>
              {props.ZHCashAddress.slice(0, 4) +
                "..." +
                props.ZHCashAddress.slice(
                  props.ZHCashAddress.length - 4,
                  props.ZHCashAddress.length
                )}
            </span>
          </div>
          <div className={classes.walletActionContainer}>
            <img
              src={CopyImg}
              className={classes.walletActionBtn}
              onClick={async () => {
                await navigator.clipboard.writeText(props.ZHCashAddress);
                setCopyImg(Check);
                setTimeout(() => setCopyImg(Copy), 2000);
              }}
            />
            {/* <img
              src={Logout}
              className={classes.walletActionBtn}
              onClick={async () => {
                await tonConnectUI.disconnect();
              }}
            /> */}
          </div>
        </div>

        {tonWallet ? (
          <div className={classes.connectedContainer}>
            <div className={classes.addressContainer}>
              <img src={TonBG} />
              <span>
                {tonAddress.slice(0, 4) +
                  "..." +
                  tonAddress.slice(tonAddress.length - 4, tonAddress.length)}
              </span>
            </div>
            <div className={classes.walletActionContainer}>
              <img
                src={CopyImgTON}
                className={classes.walletActionBtn}
                onClick={async () => {
                  await navigator.clipboard.writeText(tonAddress);
                  setCopyImgTON(Check);
                  setTimeout(() => setCopyImgTON(Copy), 2000);
                }}
              />
              <img
                src={Logout}
                className={classes.walletActionBtn}
                onClick={async () => {
                  await tonConnectUI.disconnect();
                }}
              />
            </div>
          </div>
        ) : (
          <button className={classes.connectTONBtn} onClick={()=>{open();
            setTimeout(loadUserTonInfo, 5000);
            setTimeout(loadUserTonInfo, 10000);
            setTimeout(loadUserTonInfo, 15000);
            setTimeout(loadUserTonInfo, 20000);
            setTimeout(loadUserTonInfo, 30000);
            setTimeout(loadUserTonInfo, 60000);
            setTimeout(loadUserTonInfo, 120000);}}>
            <img src={Ton} className={classes.connectTONBtnImg} />
            Connect TON Wallet
          </button>
        )}
      </div>
    </Modal>
  );
}
