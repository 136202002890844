import classes from "./ProfileWindow.module.css";
import Window from "../Window/Window";
import { useEffect, useState } from "react";
import { SERVER } from "./../../config";

export default function ProfileWindow(props) {
  const [copyReferralLinkText, setColorpyReferralLinkText] =
    useState(`Copy referral link`);


  function showInfoMessage(title, message) {
    window.Telegram.WebApp.showPopup({title: title, message: message, buttons:[{id: "ok", type:"ok"}]})
  }
  

  function copyReferralLink() {
    navigator.clipboard.writeText(
      `https://t.me/smart_lotto_oggo_bot?start=${window.Telegram.WebApp.initDataUnsafe.user.id}`
    );
    setColorpyReferralLinkText("Copied!");
    setTimeout(() => {
      setColorpyReferralLinkText(`Copy referral link`);
    }, 2000);
  }

  function getReferralElement(referral) {
    return (
      <li key={referral.name}>
        {referral.referrals && referral.referrals.length > 0 ? (
          <details>
            <summary>{referral.name}</summary>
            <ul>
              {referral.referrals.map((referral) => {
                return getReferralElement(referral);
              })}
            </ul>
          </details>
        ) : (
          referral.name
        )}
      </li>
    );
  }


  function payoutJackpot(id) {
    // remove ticket from array
    let tickets = [...winningTickets];
    tickets = tickets.filter((ticket) => ticket.id !== id);
    setWinningTickets(tickets);

    fetch(`${SERVER}/payout-jackpot`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `tma ${window.Telegram.WebApp.initData}`,
      },
      body: JSON.stringify({ ticket: Number(id) }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'ok') {
          // alert("Jackpot paid out successfully! The changes will be displayed in a few minutes");
        } else {
          alert("Error paying out jackpot: " + data.message);
        }
      });
  }


  const [referrals, setReferrals] = useState([]);
  const [winningTickets, setWinningTickets] = useState([]);

  const initData = window.Telegram.WebApp.initData;

  useEffect(() => {
    fetch(`${SERVER}/get-referrals`, {
      headers: {
        Authorization: `tma ${initData}`,
      },
    })
      .then((response) => response.json())
      .then((data) => setReferrals(data.referrals));


    fetch(`${SERVER}/get-user-winning-tickets`, {
        headers: {
          Authorization: `tma ${initData}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          let tickets = data.tickets;

          for (let i = 0; i < tickets.length; i++) {
            tickets[i]['id'] = tickets[i]['id'].toString();
          } 
          for (let i = 0; i < tickets.length; i++) {
            while (tickets[i]['id'].length < 6) {
              tickets[i]['id'] = '0' + tickets[i]['id'];
            }
          }

          // sort tickets
          tickets.sort((a, b) => a.jackpot - b.jackpot);

          setWinningTickets(tickets);
        });
  }, []);


  function convertLOT() {
    if (props.balanceLOT == 0) {
      showInfoMessage("Error", "You have no OGGO tokens to convert");
      return;
    }
    if (props.balanceLOT < 0.5) {
      showInfoMessage("Error", "You need at least 0.5 OGGO tokens to convert");
      return;
    }

    fetch(`${SERVER}/convert-lot-to-usdz`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `tma ${window.Telegram.WebApp.initData}`,
      },
      body: JSON.stringify({
        number_LOT: props.balanceLOT,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'ok') {
          showInfoMessage("Success", "The changes will be displayed in a few minutes");
        } else {
          showInfoMessage("Error", data.message);
        }
      });
  }

  // const referrals = [
  //   {
  //     name: "Ivan",
  //     referrals: [
  //       {
  //         name: "superman228",
  //         referrals: [
  //           {
  //             name: "someone third",
  //             referrals: [],
  //           },
  //         ],
  //       },
  //       {
  //         name: "iron man",
  //         referrals: [],
  //       },
  //     ],
  //   },
  //   {
  //     name: "Alex",
  //     referrals: [],
  //   },
  // ];

  return (
    <Window show={props.show} handleClose={props.handleClose}>
      {/* <button className={classes.button}>
        <i className="bi bi-gift-fill"></i> Send a Gift
      </button> */}
      <h1 className={classes.convertLOTH1}>You have {props.balanceLOT} OGGO tokens</h1>
      <button className={classes.convertLOTBtn} onClick={()=>{convertLOT()}}>Convert to USDZ</button>

      <div className={classes.divider} />

      { winningTickets && winningTickets.length != 0 && (<>
        <h1 className={classes.winningTicketsH1}>Winning tickets</h1><br></br>
        <h2 className={classes.winningTicketsH2}>Payout may take some time</h2>
      </>)}

      <div className={classes.winTicketsContainer}>
        {winningTickets && winningTickets.length != 0 ? (
          winningTickets.map((ticket) => {
            return (
              <div className={classes.winTicket} key={ticket.id}>
                <span>{ticket.id}</span>
                <span>{ticket.jackpot / (10**8)} OGGO</span>
                <button className={classes.payoutBtn} onClick={()=>{payoutJackpot(ticket.id)}}>Get</button>
              </div>
            );
          })
        ) : (
          <p className={classes.noReferrals}>There is no winning tickets</p>
        )}
      </div>

      <div className={classes.divider} />

      <button
        className={classes.button + " " + classes.copyReferralLinkButton}
        onClick={copyReferralLink}
      >
        {copyReferralLinkText != "Copied!" ? (
          <i className="bi bi-clipboard-plus-fill"></i>
        ) : (
          <i className="bi bi-clipboard-check-fill"></i>
        )}{" "}
        {copyReferralLinkText}
      </button>

      {referrals && referrals.length != 0 ? (
        <ul className={classes.referrals}>
          {referrals.map((referral) => {
            return getReferralElement(referral);
          })}
        </ul>
      ) : (
        <p className={classes.noReferrals}>You have no referrals</p>
      )}
    </Window>
  );
}
