import classes from "./MyTicketsModal.module.css";
import Modal from "../Modal/Modal";

export default function MyTicketsModal(props) {
  const tickets = props.tickets;
  // make all tickets types from number to str
  for (let i = 0; i < tickets.length; i++) {
    tickets[i] = tickets[i].toString();
  } 
  // make all tickets 6-digest at least (ex. 3 -> 000003)
  for (let i = 0; i < tickets.length; i++) {
    while (tickets[i].length < 6) {
      tickets[i] = '0' + tickets[i];
    }
  }

  return (
    <Modal show={props.show} handleClose={props.handleClose} contentClassName={classes.scrollModal}>
      <h3 className={classes.ticketsCountText}>You have {tickets.length} tickets</h3>
      <p className={classes.tickets}>{tickets.join(', ')}</p>
    </Modal>
  );
}
