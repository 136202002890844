import classes from "./ShowTourWindow.module.css";
import Window from "../Window/Window";
import wheel from "../../img/wheel.gif";
import wheelFrozen from "../../img/wheel_frozen.gif";
import { useState } from "react";
import { SERVER } from "../../config"

export default function ShowTourWindow(props) {
  const [wheelImg, setWheelImg] = useState(wheel);
  const [isWheelDisplay, setIsWheelDisplay] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [isFullList, setIsFullList] = useState(false);

  const [isWatchedTour1, setIsWatchedTour1] = useState(true);
  const [isWatchedTour2, setIsWatchedTour2] = useState(true);
  const [isWatchedTour3, setIsWatchedTour3] = useState(true);
  const [isWatchedTour4, setIsWatchedTour4] = useState(true);

  const [isCheckedWatchedTour1, setIsCheckedWatchedTour1] = useState(false);
  const [isCheckedWatchedTour2, setIsCheckedWatchedTour2] = useState(false);
  const [isCheckedWatchedTour3, setIsCheckedWatchedTour3] = useState(false);
  const [isCheckedWatchedTour4, setIsCheckedWatchedTour4] = useState(false);

  const [hasLoaded, setHasLoaded] = useState(false);

  const initData = window.Telegram.WebApp.initData

  function loadTickets() {
    // alert("loading tickets...")
    fetch(`${SERVER}/get-win-tickets?tour=${props.showingTour}`, {
      headers: {
        Authorization: `tma ${initData}`,
      }})
      .then((res) => res.json())
      .then((data) => {
        // alert(data.tickets)
        const tickets = data.tickets
        for (let i = 0; i < tickets.length; i++) {
          tickets[i] = tickets[i].toString();
        } 
        for (let i = 0; i < tickets.length; i++) {
          while (tickets[i].length < 6) {
            tickets[i] = '0' + tickets[i];
          }
        }

        // alert(tickets)
        setTickets(tickets);
      });
  }

  function startTourAnimation() {
    // alert("animating...")
    setTickets([]);
    setIsFullList(false);
    setWheelImg(wheel);
    setIsWheelDisplay(true);
    setTimeout(() => {
      setWheelImg(wheelFrozen);
      loadTickets();
      // Set tour as watched
      fetch(`${SERVER}/set-watched-tour?tour=${props.showingTour}`, {
        headers: {
          Authorization: `tma ${initData}`,
        }})
      switch (props.showingTour) {
        case 1:
          setIsWatchedTour1(true);
          break;
        case 2:
          setIsWatchedTour2(true);
          break;
        case 3:
          setIsWatchedTour3(true);
          break;
        case 4:
          setIsWatchedTour4(true);
          break;
      }
    }, 5000);
  }

  function getWatchedToursData() {
    fetch(`${SERVER}/get-watched-tours-data`, {
      headers: {
        Authorization: `tma ${initData}`,
      }})
      .then((res) => res.json())
      .then((data) => {
        switch (props.showingTour) {
          case 1:
            if (!isCheckedWatchedTour1) {
              // alert("3")
              setIsWatchedTour1(data.isWatchedTour1);
              if (data.isWatchedTour1) {
                loadTicketsWithoutAnimation();
              } else {
                startTourAnimation();
              }
              setIsCheckedWatchedTour1(true);
            }
            break;
          case 2:
            if (!isCheckedWatchedTour2) {
              setIsWatchedTour2(data.isWatchedTour2);
              if (data.isWatchedTour2) {
                loadTicketsWithoutAnimation();
              } else {
                startTourAnimation();
              }
              setIsCheckedWatchedTour2(true);
            }
            break;
          case 3:
            if (!isCheckedWatchedTour3) {
              setIsWatchedTour3(data.isWatchedTour3);
              if (data.isWatchedTour3) {
                loadTicketsWithoutAnimation();
              } else {
                startTourAnimation();
              }
              setIsCheckedWatchedTour3(true);
            }
            break;
          case 4:
            if (!isCheckedWatchedTour4) {
              setIsWatchedTour4(data.isWatchedTour4);
              if (data.isWatchedTour4) {
                loadTicketsWithoutAnimation();
              } else {
                startTourAnimation();
              }
              setIsCheckedWatchedTour4(true);
            }
            break
        }
      });
  }

  function loadTicketsWithoutAnimation() {
    // setTickets([]);
    setIsFullList(false);
    setIsWheelDisplay(false);
    loadTickets();
  }
  
  setTimeout(()=>{
    if (props.show) {
      if (hasLoaded) {
        return;
      }
      setHasLoaded(true);
      switch (props.showingTour) {
        case 1:
          if (!isCheckedWatchedTour1) {
            // alert("1")
            getWatchedToursData()
          } else {
            // alert("2")
            if (isWatchedTour1) {
              loadTicketsWithoutAnimation();
            } else {
              startTourAnimation();
            }
          }
          break;
        case 2:
          if (!isCheckedWatchedTour2) {
            getWatchedToursData()
          } else {
            if (isWatchedTour2) {
              loadTicketsWithoutAnimation();
            } else {
              startTourAnimation();
            }
          }
          break;
        case 3:
          if (!isCheckedWatchedTour3) {
            getWatchedToursData()
          } else {
            if (isWatchedTour3) {
              loadTicketsWithoutAnimation();
            } else {
              startTourAnimation();
            }
          }
          break;
        case 4:
          if (!isCheckedWatchedTour4) {
            getWatchedToursData()
          } else {
            if (isWatchedTour4) {
              loadTicketsWithoutAnimation();
            } else {
              startTourAnimation();
            }
          }
          break;
      }
    } else {
      setHasLoaded(false);
    }
  }, 100)

  // if  (!isFullList)  {
  //   tickets = tickets.slice(0, 3);
  // }

  return (
    <Window show={props.show} handleClose={props.handleClose}>
      { isWheelDisplay && <img src={wheelImg} className={classes.wheel}></img> }

      { tickets.length !== 0 && (<>
      <h1 className={classes.h1}>Winning tickets</h1>
      <div className={classes.ticketsContainer}>
        { isFullList ? (
          <>
            {tickets.map((ticket) => (
              <div className={classes.ticket} key={ticket}>
                {ticket}
              </div>
            ))}
          </>
        ) : (
          <>
            {tickets.slice(0, 3).map((ticket) => (
              <div className={classes.ticket} key={ticket}>
                {ticket}
              </div>
            ))}
          </>
        )}
      </div>

      {!isFullList && tickets.length > 3 && <button className={classes.showFullList} onClick={() => setIsFullList(true)}>See all</button>}
      </>)}
    </Window>
  );
}
