import classes from "./Firework.module.css";

export default function Firework() {
  return (
    <div className={classes.FireworkContainer}>
      <div className={classes.firework}></div>
      <div className={classes.firework}></div>
      <div className={classes.firework}></div>
      <div className={classes.firework}></div>
      <div className={classes.firework}></div>
      <div className={classes.firework}></div>
      {/* <div className={classes.firework}></div> */}
    </div>
  );
}
