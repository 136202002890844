import classes from "./WithdrawModal.module.css";
import Modal from "../Modal/Modal";
import { useEffect, useState } from "react";
import { SERVER } from "../../config";


export default function WithdrawModal(props) {
  const [numberUSDZ, setNumberUSDZ] = useState("");
  const [withdrawAddress, setWithdrawAddress] = useState("");

  function updateNumberUSDZ(event) {
    setNumberUSDZ(event.target.value);
  }
  function updateWithdrawAddress(event) {
    setWithdrawAddress(event.target.value);
  }

  function showInfoMessage(title, message) {
    window.Telegram.WebApp.showPopup({title: title, message: message, buttons:[{id: "ok", type:"ok"}]})
  }

  async function withdraw() {
    const myNumberUSDZ = Number(numberUSDZ);
    if (myNumberUSDZ < 1) {
      return showInfoMessage("Error", "Number of USDZ must be greater than 0")
    }
    if (myNumberUSDZ > props.balanceUSDZ) {
      return showInfoMessage("Error", "Not enough USDZ balance")
    }

    if (withdrawAddress === "") {
      return showInfoMessage("Error", "Please enter a withdrawal address")
    }

    await fetch(`${SERVER}/withdraw-usdz`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `tma ${window.Telegram.WebApp.initData}`,
      },
      body: JSON.stringify({
        number_USDZ: myNumberUSDZ,
        withdraw_address: withdrawAddress
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          showInfoMessage("Success", "USDZ will be sent to your withdrawal address.")
          props.handleClose()
        } else {
          showInfoMessage("Error", data.message)
        }
      });
  }

  return (
    <Modal show={props.show} handleClose={props.handleClose}>
      <h2 className={classes.h2}>Withdrawal of USDZ to another wallet</h2>

      <input
        className={classes.numberUSDZ}
        onChange={updateNumberUSDZ}
        value={numberUSDZ}
        type="number"
        min="1"
        placeholder="Number of USDZ"
      />

      <input
        className={classes.withdrawAddress}
        onChange={updateWithdrawAddress}
        value={withdrawAddress}
        type="text"
        placeholder="Withdrawal address"
      />

      <button className={classes.withdrawButton} onClick={withdraw}>
        Withdraw
      </button>
    </Modal>
  );
}
